import React from "react";
import "./styles.css";

function App() {
  return (
    <div className="Main">
      <p className="BrandLabel">Mizu Development LLC</p>
      <p className="BrandDescriptionLabel">A holding company</p>
      <a className="contact-link" href="mailto:team@mizudev.com">
        Contact us about our products
      </a>
    </div>
  );
}

export default App;
